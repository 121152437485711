<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="name" :label="$t('name')">
                <ElSelect
                  v-model="form.name"
                  style="width: 100%"
                  :placeholder="$t('select_an_option')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                >
                  <el-option
                    v-for="item in countries"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
  </div>
</template> 
<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { getListCountries } from '~/composables/models/countries/index'
import CREATE_COUNTRY from '~/graphql/countries/mutation/createCountry.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  name: '',
  code: ''
})

const emits = defineEmits(['create'])
const countries = getListCountries()
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)

const rules = {
  name: { required }
}

onMounted(() => {
  initialCountries()
})

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  name: [
    {
      required: true,
      message: t('name_is_required'),
      trigger: 'blur'
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
    await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const variables = {
    name: transformCamelCase(form.name),
    code: countries.find(i => i.value === form.name).iso_code
  }
  if (!variables.name || !variables.code) {
    $showError(t('form_is_invalid'))
    loading.value = false
    return       
  }
  const response = await mutation(CREATE_COUNTRY, variables)
  if (response.error) {
    loading.value = false
    if (response.error.message.includes('input.name') || response.error.message.includes('input.code')) {
      $showError(t('country_already_exists'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'create')
    }
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createCountry ?? null)
    loading.value = false
  }
}

const transformCamelCase = (string) => {
    const words = string.split('_')

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
    }

    return words.join(' ')
}
</script>
            